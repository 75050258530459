import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./UserTable.css";

const UserTable = () => {
  const [codes, setCodes] = useState([]); // Codes fetched from API
  const [searchTerm, setSearchTerm] = useState(""); // Search input state

  // Fetch data from API on component mount
  useEffect(() => {
    fetch("https://leader-acadmy.hwnix.com/api/code/users")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Data:", data);
        setCodes(data.codes || []); // Safely set codes array
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Reset Code API call
  const handleResetCode = (code) => {
    fetch(`https://leader-acadmy.hwnix.com/api/reset_code/${code}`, { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        console.log(`Code ${code} reset successfully:`, data);
        // Optionally refresh or modify codes state here
      })
      .catch((error) => console.error(`Error resetting code ${code}:`, error));
  };

  // Filter codes based on search term
  const filteredCodes = codes.filter(
    (code) =>
      code.code.includes(searchTerm) || 
      (code.user && code.user.phone.includes(searchTerm))
  );

  // Further filter codes longer than 8 digits
  const validCodes = filteredCodes.filter((code) => code.code.length > 8);

  // Count the number of "Not used" names
  const notUsedCount = validCodes.filter((code) => !code.user || !code.user.name).length;

  return (
    <motion.div
      className="table-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>User Codes</h2>

      {/* Display counters */}
      <div className="counters">
        <p>
          <strong>Total Valid Codes:</strong> {validCodes.length}
        </p>
        <p>
          <strong>Not Used Codes:</strong> {notUsedCount}
        </p>
      </div>

      {/* Search bar */}
      <input
        type="text"
        placeholder="Search by code or phone"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      {/* Table of codes */}
      {validCodes.length > 0 ? (
        <table className="user-table">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Reset Code</th>
            </tr>
          </thead>
          <tbody>
            {validCodes.map((code) => (
              <motion.tr
                key={code.id}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <td>{code.code}</td>
                <td>{code.user?.name || "Not used"}</td>
                <td>{code.user?.phone || "Not used"}</td>
                <td>
                  <button
                    onClick={() => handleResetCode(code.code)}
                    className="reset-button"
                  >
                    Reset
                  </button>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No data available.</p>
      )}
    </motion.div>
  );
};

export default UserTable;
