import React, { useState } from 'react';
import axios from 'axios';
import './Login.css'; // Assuming you will create this file for styling
import { useNavigate } from 'react-router-dom';
import SidebarComponent from '../SidebarComponent';
import Bubbles from '../Bubbles';

const Login = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post('https://leader-acadmy.hwnix.com/api/login', {
        phone,
        password,
      });
      const { data } = res;
      setResponse(data);

      // Save token and educational_level_id to local storage
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('educationalLevelId', data.user.educational_level_id);
      localStorage.setItem('id', data.user.id);

      // Navigate to /Home2 if user ID is 60, otherwise go to /PricingCard
     
        navigate('/PricingCard');

      console.log('Login Success:', data);
    } catch (error) {
      setError('Login Failed: ' + 'برجاء التاكد من الايميل او كلمه السر 🚫');
      console.error('Login Failed:', error.response ? error.response.data : error.message);
    }
  };

  const BachHome = async (e) => {
    navigate('/');
  };

  return (
    <div className="login-container">
      <div style={{ width: "100%", height: "100%", position: "absolute" }}>
        <Bubbles />
      </div>

      <div className="login-form">
        <div className="login-header">
          <div className="login-title animiL">Log In</div>
          <div className="signup-link animiL">
            <span>Don’t have an Account? </span>
            <span className="create-account animiL"><a href='/Signup'>Create Account</a></span>
          </div>
        </div>
        <form className="input-group animiL" onSubmit={handleSubmit}>
          <div className="input-group animiL">
            <div className="input-label">Mobile Number</div>
            <div className="input-field">
              <input className="input-field animiL"
                type="text"
                placeholder="010000000"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="input-group animiL">
            <div className="input-label">Password</div>
            <div className="input-field">
              <input className="input-field"
                type="password"
                placeholder="Enter password here"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="login-button">
  <button type="submit">Log In</button>
</div>
<div className="login-button">
  <button onClick={BachHome}>الرئسيه</button>
</div>

        </form>
        <br /> <br />

        {response && <div className="response">Login Successful</div>}
        {error && <div className="error">{error}</div>}
      </div>

      <div className="login-banner animiR">
        <div className="banner-overlay" />
        <div className="banner-text2">
          Unlock Your English Potential Today!
        </div>
      </div>
    </div>
  );
};

export default Login;
