import React, { useState, useEffect, useRef } from "react";
import './APPS.css'; 
import whatsappLogo from "./whatsapp-logo.png"; // Adjust the path as needed
import Facebooklogo from "./Facebooklogo.jpg"; // Adjust the path as needed
import Youtubelogo from "./Youtube-logo.jpg"; // Adjust the path as needed


function BarContect() {
  
const handleWhatsAppClick = () => {
  window.open("https://wa.me/0201067123409", "_blank");
};

const handleFacebookClick = () => {
  window.open("https://www.facebook.com/AshrafAbed2020", "_blank");
};

const handleYoutbeeClick = () => {
  window.open("https://www.youtube.com/channel/UC2_e1-9trV5x3beP_wXQfpw", "_blank");
};
  return (
      <>

     
      <div className="whatsapp-logo-container">
          <img
            src={whatsappLogo}
            alt="WhatsApp Logo"
            className="whatsapp-logo"
            onClick={handleWhatsAppClick}
          />
        </div>
        
        {/* Facebook Logo */}
        <div className="Facebook-logo-container">
          <img
            src={Facebooklogo}
            alt="Facebook Logo"
            className="whatsapp-logo"
            onClick={handleFacebookClick}
          />
        </div>
        
        {/* YouTube Logo */}
        <div className="Youtube-logo-container">
          <img
            src={Youtubelogo}
            alt="YouTube Logo"
            className="whatsapp-logo"
            onClick={handleYoutbeeClick}
          />
        </div>
    </>
  );
}




export default BarContect;
