import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProfileCard from './componant/ProfileCard';
import Login from './componant/Login';
import Signup from './componant/Signup';
import Video from './componant/Video';
import APPS from './APPS';
import AssignmentsPage from './componant/AssignmentsPage';
import PdfList from './componant/PdfList';
import PricingCard from './componant/PricingCard';
import AddQuestion from './componant/AddQuestion';
import LessonTable from './componant/LessonTable';
import ListStudent from './componant/ListStudent';
import UserTable from './componant/UserTable';
import UserTable2 from './componant/UserTable2.js';
import UserTable3 from './componant/UserTable3.js';
import UserTable4  from './componant/UserTable4.js';

import CreateCodePage from './componant/CreateCodePage';
import MessagesTable from './componant/MessagesTable';
import Words from './componant/Words';
import APPS2 from './APP2.js';
import Home2 from './componant/FormPage';
import APPS22 from './NewPlatform/APP2.js';
import APPS1 from './NewPlatform/APPS.js';
import Home from './NewPlatform/Home.js';
import StudentPackages from './NewPlatform/StudentPackages';
import Login2 from './NewPlatform/Login2.js';
import PricingCard2 from './NewPlatform/PricingCard.js';
import PricingCard22 from './NewPlatform/PricingCard22.js';

import ProfileCard2 from './NewPlatform/ProfileCard';
import Video2 from './NewPlatform/Video';
import Signup2 from './NewPlatform/Signup';
import AssignmentsPage2 from './NewPlatform/AssignmentsPage';
import PdfList2 from './NewPlatform/PdfList';
import EducationalVideos from './NewPlatform/EducationalVideos';

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobile2, setIsMobile2] = useState(false);

  // Function to check if the screen width is mobile-sized
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize(); // Check screen size on initial load
    window.addEventListener('resize', handleResize); // Listen for window resize events
    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup event listener on component unmount
    };
  }, []);

  
  const handleResize2 = () => {
    setIsMobile2(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize2(); // Check screen size on initial load
    window.addEventListener('resize', handleResize2); // Listen for window resize events
    return () => {
      window.removeEventListener('resize', handleResize2); // Cleanup event listener on component unmount
    };
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="Signup" element={<Signup />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/ProfileCard" element={<ProfileCard />} />
        <Route path="/Video" element={<Video />} />
        <Route path="/Signin" element={<Login2 />} />
        <Route path="MakeAccount" element={<Signup2 />} />

        {/* Conditional Rendering Based on Screen Size */}
        <Route path="/" element={isMobile ? <APPS /> : <APPS2 />} />
        <Route path="/Home" element={isMobile2 ? <APPS1 /> : <APPS22 />} />

        <Route path="/AssignmentsPage" element={<AssignmentsPage />} />
        <Route path="/PdfList" element={<PdfList />} />
        <Route path="/PricingCard" element={<PricingCard />} />
        <Route path="/AddQuestion" element={<AddQuestion />} />
        <Route path="/LessonTable" element={<LessonTable />} />
        <Route path="/ListStudent" element={<ListStudent />} />
        <Route path="/UserTable" element={<UserTable />} />
        <Route path="/User" element={<UserTable2 />} />
        <Route path="/EducationalVideos" element={<EducationalVideos />} />
        <Route path="/Codes-mohamed" element={<UserTable3 />} />
        <Route path="/Codes-Ashraf" element={<UserTable4 />} />

        <Route path="/Create" element={<CreateCodePage />} />
        <Route path="/Message" element={<MessagesTable />} />
        <Route path="/Words" element={<Words />} />
        <Route path="/Home2" element={<Home2 />} />
        <Route path="/Teachers" element={<Home />} />
        <Route path="/StudentPackages" element={<StudentPackages />} />
        <Route path="/Card" element={<PricingCard2 />} />      
         <Route path="/card2" element={<PricingCard22 />} />

        <Route path="/Cardlec" element={<ProfileCard2 />} />
        <Route path="/VIDEOs" element={<Video2 />} />
        <Route path="/PdfList2" element={<PdfList2 />} />
        <Route path="/AssignmentsPage2" element={<AssignmentsPage2 />} />

        {/* Define other routes here */}
      </Routes>
    </Router>
  );
}

export default App;