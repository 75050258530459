import React from 'react';
import './Sidebar.css';

const SidebarComponent1 = () => {
  return (
    <div className="page-container2">
      <div className="left-sidebar2">
      </div>
      <div className="right-sidebar2">
      </div>
     
    </div>
  );
};

export default SidebarComponent1;
