import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import { gsap } from 'gsap';

// Helper function to generate random colors
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const Letter = ({ letter }) => {
  const ref = useRef();

  // Random color for each letter
  const color = getRandomColor();

  // Animate the letters to move across the screen
  useEffect(() => {
    gsap.to(ref.current.position, {
      x: Math.random() * 10 - 5,  // Random x-axis movement
      y: Math.random() * 10 - 5,  // Random y-axis movement
      z: Math.random() * 10 - 5,  // Random z-axis movement
      duration: Math.random() * 3 + 1,  // Random speed
      repeat: -1,  // Infinite repeat
      yoyo: true,  // Reverse the animation after each run
      ease: 'power1.inOut'
    });
  }, []);

  // Rotate the letters
  useFrame(() => {
    ref.current.rotation.x += 0.01;
    ref.current.rotation.y += 0.01;
  });

  return (
    <Text
      ref={ref}
      fontSize={1}
      color={color}  // Apply the random color here
      position={[Math.random() * 10 - 5, Math.random() * 10 - 5, Math.random() * 10 - 5]}
    >
      {letter}
    </Text>
  );
};

const FlyingLetters = () => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  return (
    <Canvas>
      {letters.map((letter, index) => (
        <Letter key={index} letter={letter} />
      ))}
    </Canvas>
  );
};

export default FlyingLetters;
