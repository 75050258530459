import React, { useState } from 'react';
import './FormPage.css';  // Assuming you're using a separate CSS file for styling

const FormPage = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    packageName: '',
    packageDescription: '',
    lessonId: '',
    lessonName: '',
    lessonDescription: '',
    videoUrl: '',
    videoTitle: ''
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    // You can now send the data to your API or further process it
  };

  return (
    <div className="form-container">
      <h2>Submit Package, Lesson, and Video Info</h2>
      <form onSubmit={handleSubmit}>
        {/* Package Section */}
        <div className="form-section">
          <h3>Package Information</h3>
          <label>
            Package Name:
            <input
              type="text"
              name="packageName"
              value={formData.packageName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Package Description:
            <textarea
              name="packageDescription"
              value={formData.packageDescription}
              onChange={handleChange}
              required
            />
          </label>
        </div>

        {/* Lesson Section */}
        <div className="form-section">
          <h3>Lesson Information</h3>
          <label>
            Lesson ID:
            <input
              type="text"
              name="lessonId"
              value={formData.lessonId}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Lesson Name:
            <input
              type="text"
              name="lessonName"
              value={formData.lessonName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Lesson Description:
            <textarea
              name="lessonDescription"
              value={formData.lessonDescription}
              onChange={handleChange}
              required
            />
          </label>
        </div>

        {/* Video Section */}
        <div className="form-section">
          <h3>Video Information</h3>
          <label>
            Video URL:
            <input
              type="url"
              name="videoUrl"
              value={formData.videoUrl}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Video Title:
            <input
              type="text"
              name="videoTitle"
              value={formData.videoTitle}
              onChange={handleChange}
              required
            />
          </label>
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default FormPage;
