import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

function TeacherCard({ name, image }) {
  return (
    <div className="teacher-card" style={styles.teacherCard}>
      <img src={image} alt={name} style={styles.teacherCardImage} />
      <h3 style={styles.teacherCardName}>{name}</h3>
    </div>
  );
}

function About2() {
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await fetch('https://leader-acadmy.hwnix.com/api/getteachers');
        const data = await response.json();
        setTeachers(data.teachers); // Adjusted to match the API response structure
      } catch (error) {
        console.error('Error fetching the teachers:', error);
      }
    };

    fetchTeachers();
  }, []);

  const handleNavigation = () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/PricingCard');
    } else {
      navigate('/Login');
    }
  };

  return (
    <>
      <div className="latest-teachers" style={styles.latestTeachers}>
        <div className="latest-teachers-header" style={styles.latestTeachersHeader}>
          <h2 style={styles.headerText}>
            أحدث المعلمين
          </h2>
        </div>
        <div className="teacher-cards" style={styles.teacherCards}>
          <section className="teachers-section">
            {teachers.map((teacher) => (
              <TeacherCard
                key={teacher.id}
                name={teacher.name}
                image={teacher.image}
              />
            ))}
          </section>
        </div>
        <div style={styles.gradientBackground}></div>
        <div style={styles.viewMoreContainer}>
          <a onClick={handleNavigation} style={styles.viewMoreLink}>
            عرض المزيد
          </a>
        </div>
      </div>
    </>
  );
}

const styles = {
  latestTeachers: {
    position: 'relative',
    padding: '2vw 0',
  },
  latestTeachersHeader: {
    textAlign: 'center',
    marginBottom: '2vw',
  },
  headerText: {
    color: '#454545',
    fontSize: '2vw',
    fontFamily: 'Lemonada',
    fontWeight: 575,
    wordWrap: 'break-word',
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
  },
  teacherCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    gap: '2vw',
  },
  teacherCard: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
  },
  teacherCardImage: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px 10px 0 0',
  },
  teacherCardName: {
    fontSize: '1.5vw',
    margin: '1vw 0',
    padding: '0 1vw',
  },
  gradientBackground: {
    width: "100%",
    height: "155vh",
    position: "absolute",
    top: "28%",
    background: "linear-gradient(180deg, rgba(96, 128, 191, 0) 0%, rgba(96, 128, 191, 0.25) 25%, rgba(96, 128, 191, 0.50) 50%, #6080BF 100%)",
    backdropFilter: "blur(13px)",
  },
  viewMoreContainer: {
    width: "20vw",
    height: "10vh",
    left: "40vw",
    top: "40%",
    position: "absolute",
    opacity: "0.75",
    background: "white",
    borderRadius: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  viewMoreLink: {
    color: "#000",
    fontSize: '1.5vw',
    fontFamily: 'Lemonada',
    fontWeight: 575,
    textDecoration: 'none',
    cursor: 'pointer',
  },
};

export default About2;
