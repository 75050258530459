import React, { useState, useEffect } from 'react';
import './PricingCard.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SubscriptionModal from './SubscriptionModal2';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Footer from './Footer2';
import Headerpricing from './Headerpricing';
import Header2 from './Header2card';
// import Bubbles from '../Bubbles';
// import Sidebar from '../Sidebar';

const PricingCard = () => {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deviceFingerprint, setDeviceFingerprint] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const educationalLevelId = localStorage.getItem('educationalLevelId');
        const authToken = localStorage.getItem('authToken');
        const Teacher_id = localStorage.getItem('teacher_id');

        if (!authToken || !educationalLevelId) {
          navigate('/Card');
          return;
        }
  
        const response = await axios.get(
          `https://leader-acadmy.hwnix.com/api/getPackages/44/${educationalLevelId}`, 
          {
            headers: { Authorization: `Bearer ${authToken}` }
          }
        );
  
        if (response.data.packages && response.data.packages.length > 0) {
          setLessons(response.data.packages || []);
        } else {
          alert("لا يوجد محاضرات متاحة.");
          setLessons([]);
        }
      } catch (err) {
        setError(err);
        if (err.response && err.response.status === 404) {
          alert("لا يوجد محاضرات متاحة.");
        } else {
          alert("حدث خطأ أثناء تحميل المحاضرات.");
        }
      } finally {
        setLoading(false);
      }
    };
  
    const fetchDeviceFingerprint = async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        setDeviceFingerprint(result.visitorId);
        localStorage.setItem('deviceFingerprint', result.visitorId);
      } catch (err) {
        setErrorMessages(prev => ({ ...prev, global: 'Could not fetch device fingerprint' }));
        navigate('/Signin');
      }
    };

    fetchLessons();
    fetchDeviceFingerprint();
  }, [navigate]);

  const handleSubscribeClick = (lesson) => {
    localStorage.setItem('package_id', lesson.id);
    setSelectedLesson(lesson);
    setIsModalOpen(true);
  };

  const handleEnterClick = async (lesson) => {
    localStorage.setItem('package_id', lesson.id);

    const id = localStorage.getItem('id');
    const subscriptionCode = localStorage.getItem('subscriptionCode');
    const deviceFingerprint = localStorage.getItem('deviceFingerprint');
    
    try {
      const response = await axios.get(
        `https://leader-acadmy.hwnix.com/api/code/check/${id}/${deviceFingerprint}/${lesson.id}?code=${subscriptionCode}`
      );
      const result = response.data;
    
      if (result.message === "User has a valid code.") {
        navigate('/Cardlec');
      } else {
        setErrorMessages(prev => ({ ...prev, [lesson.id]: "الكود غير صالح أو يوجد مشكلة في الكود." }));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "MAC address mismatch." || error.response.data.message === "No code found for this user and lesson.") {
          setErrorMessages(prev => ({ ...prev, [lesson.id]: "برجاء الدخول من اول جهاز تم الاشتراك عليه" }));
        } else {
          setErrorMessages(prev => ({ ...prev, [lesson.id]: "من فضلك ادخل الكود." }));
        }
      } else {
        setErrorMessages(prev => ({ ...prev, [lesson.id]: "حدث خطأ أثناء التحقق من الكود." }));
      }
    }
  };

  const handleKeydown = (e) => {
    if (e.key === 'F12' || (e.ctrlKey && (e.key === 'u' || (e.shiftKey && e.key === 'I')))) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('contextmenu', (e) => e.preventDefault());
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => setIsMobile(window.innerWidth <= 768);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleEnters = () => {
    navigate('/card');
  };
  
  

  const handleEnter = async () => {
    navigate('/EducationalVideos');

  };
  return (
    <>
      {isMobile ? <Header2 /> : <Headerpricing />}
      <br/>
      <br/>
      <br/>
      <div className="center-button-container">
  <button className="button_revasion" onClick={handleEnters}>الشهور</button>
</div>

      <div className="page-container">
        {/* <Sidebar /> */}

        <div className="profile-card">
          <div style={{ width: "100%", height: "100%", position: "absolute" }}>
            {/* <Bubbles /> */}
          </div>
 
          <div className="pricing-cards animiL">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>Error loading lessons: {error.message}</p>
            ) : (
              lessons.map(lesson => (
                <div key={lesson.id} className="card">
                  <div className="card-header">
                    <h2>{lesson.title}</h2>
                  </div>
                  <div className="card-body">
                    <p className="lesson-description">{lesson.description}</p>
                    {errorMessages[lesson.id] && (
                      <p className="error">{errorMessages[lesson.id]}</p>
                    )}
                  </div>
                  
                  <div className="card-footer">
                    
                    <button className="select-button" onClick={() => handleEnterClick(lesson)}>دخول</button>
                    <button className="select-button" onClick={() => handleSubscribeClick(lesson)}>اشتراك</button>
                  </div>
                </div>
              ))
            )}
                      </div>

            {isModalOpen && (
              <SubscriptionModal
                lesson={selectedLesson}
                onClose={() => setIsModalOpen(false)}
              />
            )}
          
          </div>
          </div>
      <Footer />
    </>
  );
};

export default PricingCard;
