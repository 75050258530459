import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './UserTable.css';

const UserTable2 = () => {
  const [codes, setCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input

  // Fetch data on component mount
  useEffect(() => {
    fetch('https://leader-acadmy.hwnix.com/api/code/users')
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched Data:', data); // Inspect the response structure
        setCodes(data.codes || []); // Set the codes array from the response
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // Reset code handler
  const handleResetCode = (code) => {
    fetch(`https://leader-acadmy.hwnix.com/api/reset_code/${code}`, { method: 'GET' })
      .then((response) => response.json())
      .then((data) => {
        console.log(`Code ${code} reset successfully:`, data);
        // Optionally refresh codes or update specific state
      })
      .catch((error) => console.error(`Error resetting code ${code}:`, error));
  };

  // Filtered codes based on search term and length condition
  const filteredCodes = codes
    .filter((code) => code.code.length <= 8) // Filter codes with 8 or fewer digits
    .filter(
      (code) =>
        code.code.includes(searchTerm) || // Match search term with code
        (code.user?.phone && code.user.phone.includes(searchTerm)) // Match search term with phone
    );

  // Count "Not used" entries
  const notUsedCount = filteredCodes.filter((code) => !code.user?.name).length;

  return (
    <motion.div
      className="table-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>User Codes</h2>
      
      {/* Display count of "Not used" */}
      <p className="not-used-counter">
        Number of "Not used" codes: <strong>{notUsedCount}</strong>
      </p>

      {/* Search bar */}
      <input
        type="text"
        placeholder="Search by code or phone"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      {filteredCodes.length > 0 ? (
        <table className="user-table">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Reset Code</th>
            </tr>
          </thead>
          <tbody>
            {filteredCodes.map((code) => (
              <motion.tr
                key={code.id}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <td>{code.code}</td>
                <td>{code.user?.name || 'Not used'}</td>
                <td>{code.user?.phone || 'Not used'}</td>
                <td>
                  <button
                    onClick={() => handleResetCode(code.code)}
                    className="reset-button"
                  >
                    Reset
                  </button>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No data available.</p>
      )}
    </motion.div>
  );
};

export default UserTable2;
