import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './UserTable.css';

const UserTable3 = () => {
  const [codes, setCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [codeCount, setCodeCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newCodeMessage, setNewCodeMessage] = useState('');
  const [newCode, setNewCode] = useState('');

  // Fetch codes from the API
  useEffect(() => {
    fetch('https://leader-acadmy.hwnix.com/api/getcode/ashraf')
      .then((response) => response.json())
      .then((data) => {
        setCodes(data.code || []);
        setCodeCount(data.code_count || 0);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      });
  }, []);

  // Reset a specific code
  const handleResetCode = (code) => {
    fetch(`https://leader-acadmy.hwnix.com/api/reset_code/${code}`, { method: 'GET' })
      .then((response) => response.json())
      .then((data) => {
        console.log(`Code ${code} reset successfully:`, data);
        setCodes((prevCodes) => prevCodes.filter((item) => item.code !== code));
        setCodeCount((prevCount) => prevCount - 1); // Update total count
      })
      .catch((error) => console.error(`Error resetting code ${code}:`, error));
  };

  // Create a new code
  const handleCreateCode = () => {
    fetch('https://leader-acadmy.hwnix.com/api/addcode/ashrafabed')
      .then((response) => response.json())
      .then((data) => {
        console.log('New code created:', data);
        setNewCodeMessage(data.message);
        setNewCode(data.code.code);
        setCodes((prevCodes) => [...prevCodes, data.code]);
        setCodeCount((prevCount) => prevCount + 1); // Update total count
      })
      .catch((error) => {
        console.error('Error creating code:', error);
        setNewCodeMessage('Failed to create a new code.');
      });
  };

  // Filter codes based on search term and code length
  const filteredCodes = codes
    .filter((code) => code.code.length >= 0)
    .filter((code) =>
      code.code.includes(searchTerm) ||
      (code.user && code.user.phone.includes(searchTerm))
    );

  // Compute counts for names and 'Not used'
  const nameCount = filteredCodes.filter((code) => code.user?.name).length;
  const notUsedCount = filteredCodes.length - nameCount;

  // Conditional rendering for loading and error states
  if (loading) {
    return <p className="loading-message">Loading...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  // Render the table
  return (
    <motion.div
      className="table-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>User Codes</h2>
      <h3>Total Filtered Codes: {filteredCodes.length}</h3>
      <h4>
        Codes with Names: {nameCount} | Not Used: {notUsedCount}
      </h4>
      <button onClick={handleCreateCode} className="create-button">
        Create New Code
      </button>
      {newCodeMessage && (
        <p className="success-message">
          {newCodeMessage} {newCode && `Generated Code: ${newCode}`}
        </p>
      )}
      <br/>
      <input
        type="text"
        placeholder="Search by code or phone"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

   

 

      {filteredCodes.length > 0 ? (
        <table className="user-table">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Reset Code</th>
            </tr>
          </thead>
          <tbody>
            {filteredCodes.map((code) => (
              <motion.tr
                key={code.id}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <td>{code.code}</td>
                <td>{code.user?.name || 'Not used'}</td>
                <td>{code.user?.phone || 'Not used'}</td>
                <td>
                  <button
                    onClick={() => handleResetCode(code.code)}
                    className="reset-button"
                  >
                    Reset
                  </button>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No matching data found.</p>
      )}
    </motion.div>
  );
};

export default UserTable3;
