import React, { useState, useEffect } from 'react';
import './EducationalVideos.css'; // Custom CSS
import { useNavigate } from 'react-router-dom';

const EducationalVideos = () => {
  const [educationalLevelId, setEducationalLevelId] = useState(null);
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedLevelId = localStorage.getItem('educationalLevelId');
    setEducationalLevelId(storedLevelId);

    if (storedLevelId === '1') {
      setVideos(['https://www.youtube.com/embed/8qCC_NeuAHY']);
    } else if (storedLevelId === '2') {
      setVideos([
        'https://www.youtube.com/embed/rD5EqQxIGVs',
        'https://www.youtube.com/embed/eUdTUAcYaC4',
        'https://www.youtube.com/embed/F-ronwSkgfw',
        'https://www.youtube.com/embed/ejKyu_xgT00',
      ]);
    }
  }, []);
  const handleEnter = async () => {
    navigate('/Card');

  };
  return (
    <div className="videos-page">
      <h1 className="page-title">المحاضرات المجانيه</h1>
      {videos.length > 0 ? (
        <div className="videos-grid">
          {videos.map((video, index) => (
            <div key={index} className="video-card">
              <iframe
                src={video}
                title={`Video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p className="video-title">Video {index + 1}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-videos-message">No videos available for this educational level.</p>
      )}
                          <button className="select-button" onClick={() => handleEnter()}>Back</button>

    </div>
  );
};

export default EducationalVideos;
