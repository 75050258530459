import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import './About.css';
import './PricingCard.css';
import axios from 'axios';
import facebookImage from './Assets/facebook.svg';
import youtube from './Assets/social-icons-white-youtube.svg';
const SocialIcon = ({ src, alt, href }) => (
  <div className="social-icon">
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img src={src} alt={alt} />
    </a>
  </div>
);
function Header() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogout = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    try {
      await axios.post('https://leader-acadmy.hwnix.com/api/logout', {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      localStorage.clear();
      setIsAuthenticated(false);
      navigate('/');
    } catch (error) {
      navigate('/login');
    }
  };

  return (
    <>
      <header className="profile-header">
          <div className="profile-header-content">
            <button className="nav-button create-account-button animiL" onClick={handleLogout}>تسجيل الخروج</button>
            <div className="social-links">
              <SocialIcon src={facebookImage} alt="Facebook" href="https://www.facebook.com/AshrafAbed2020/photos" />
              <SocialIcon src={youtube} alt="YouTube" href="https://www.youtube.com/channel/UC2_e1-9trV5x3beP_wXQfpw" />
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Link to="/" style={styles.link}><button style={styles.button}>الرئيسيه</button></Link>
              <Link to="/" style={styles.link}><button style={styles.button}>عن المنصه</button></Link>
            </div>
            <div className="profile-name animiR">Mr.Ashraf Abed</div>
          </div>
        </header>

    </>
  );
}

export default Header;

const styles = {
  button: {
    height: "33px",
    background: "white",
    borderRadius: "7px",
    border: "1px solid #487EEA",
    color: "#487EEA",
    fontFamily: "Inter",
    fontWeight: "400",
    padding: "5 16px",
    cursor: "pointer",
    transition: "background 0.3s, color 0.3s",
  },
  link: {
    textDecoration: "none",
  },
};