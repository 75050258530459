import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import './StudentPackages.css'; // Create CSS for styling
import Footer from '../componant/Footer';
import Header2 from './Header2';
import { useNavigate } from 'react-router-dom';

const StudentPackages = () => {
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deviceFingerprint, setDeviceFingerprint] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});

  // Get user_id from localStorage
  const user_id = localStorage.getItem('id');

  useEffect(() => {
    // Fetch the packages data from the API
    const fetchPackages = async () => {
      try {
        const response = await axios.get(`https://leader-acadmy.hwnix.com/api/get/packages/${user_id}`);
        setPackages(response.data.valid_packages);
        setLoading(false);
      } catch (error) {
        setError("Error fetching packages data.");
        setLoading(false);
      }
    };

    fetchPackages();
  }, [user_id]);

  const handleEnterClick = async (pkg) => {
    localStorage.setItem('package_id', pkg.id);

    const id = localStorage.getItem('id');
    const subscriptionCode = localStorage.getItem('subscriptionCode');
    const deviceFingerprint = localStorage.getItem('deviceFingerprint');
    
    try {
      const response = await axios.get(
        `https://leader-acadmy.hwnix.com/api/code/check/${id}/${deviceFingerprint}/${pkg.id}?code=${subscriptionCode}`
      );
      const result = response.data;
    
      if (result.message === "User has a valid code.") {
        navigate('/Cardlec');
      } else {
        setErrorMessages(prev => ({ ...prev, [pkg.id]: "الكود غير صالح أو يوجد مشكلة في الكود." }));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "MAC address mismatch." || error.response.data.message === "No code found for this user and lesson.") {
          setErrorMessages(prev => ({ ...prev, [pkg.id]: "برجاء الدخول من اول جهاز تم الاشتراك عليه" }));
        } else {
          setErrorMessages(prev => ({ ...prev, [pkg.id]: "من فضلك ادخل الكود." }));
        }
      } else {
        console.log(error.message); // For network or other errors
        setErrorMessages(prev => ({ ...prev, [pkg.id]: "حدث خطأ أثناء التحقق من الكود." }));
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Header2 />
      <div className="package-container">
        {packages.map((pkg) => (
          <motion.div
            key={pkg.id}
            className="package-card"
            whileHover={{ scale: 1.05, rotateY: 10 }}
            transition={{ duration: 0.5 }}
          >
            <h3>{pkg.title}</h3>
            <p>{pkg.description}</p>
            <p>Subject ID: {pkg.subject_id}</p>
            <p>Teacher ID: {pkg.teacher_id}</p>
            <button className="select-button" onClick={() => handleEnterClick(pkg)}>دخول</button>
            {errorMessages[pkg.id] && <p className="error-message">{errorMessages[pkg.id]}</p>}
          </motion.div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default StudentPackages;
