import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import Tilt from "react-parallax-tilt";
import "./Home.css";
import Footer2 from "./Footer2";
import Header2 from "./Header22";
const Teachers = () => {
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const educationalLevelId = localStorage.getItem('educationalLevelId');

        const response = await axios.get(`https://leader-acadmy.hwnix.com/api/teacher/educational-level/${educationalLevelId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTeachers(response.data.teachers || []);
      } catch (error) {
        console.error("Error fetching teachers:", error);
        setTeachers([]);
      }
    };

    fetchTeachers();
  }, []);

  const handleTeacherClick = (teacherId) => {
    localStorage.setItem("teacher_id", teacherId);
  };

  return (
    <>
<Header2/>
    <div className="teachers-container">
      {teachers.length > 0 ? (
        teachers.map((teacher) => (
          <motion.div
            key={teacher.id}
            className="teacher-card"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Tilt tiltMaxAngleX={25} tiltMaxAngleY={25} scale={1.05}>
              <a
                href={`/PricingCard}`}
                className="teacher-image-link"
                onClick={() => handleTeacherClick(teacher.id)}
              >
                <img src={teacher.FullSrc} alt={teacher.name} className="teacher-image" />
              </a>
              <h2>{teacher.name}</h2>
              <p><strong>Educational Level:</strong> {teacher.educational_level}</p>
              <p><strong>Subject:</strong> {teacher.subject}</p>
              <a
                href={`/Card`}
                className="teacher-link"
                onClick={() => handleTeacherClick(teacher.id)}
              >
                اعرف التفاصيل
              </a>
            </Tilt>
          </motion.div>
        ))
      ) : (
        <p>No teachers found.</p>
      )}
    </div>
    <Footer2/>
    </>
  );
};

export default Teachers;
