import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import './About.css';
import './PricingCard.css';
import axios from 'axios';
import facebookImage from '../componant/Assets/facebook.svg';
import youtube from '../componant/Assets/social-icons-white-youtube.svg';
import logo from './Logo.png'; // Assuming your logo is in the src folder

const SocialIcon = ({ src, alt, href }) => (
  <div className="social-icon">
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img src={src} alt={alt} />
    </a>
  </div>
);
function Header() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogout = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    try {
      await axios.post('https://leader-acadmy.hwnix.com/api/logout', {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      localStorage.clear();
      setIsAuthenticated(false);
      navigate('/Home');
    } catch (error) {
      navigate('/Signin');
    }
  };

  return (
    <>
      <header className="profile-header">
          <div className="profile-header-content">
            <button className="nav-button create-account-button animiL" onClick={handleLogout}>تسجيل الخروج</button>
            <div className="social-links">
              <SocialIcon src={facebookImage} alt="Facebook" href="https://www.facebook.com/AshrafAbed2020/photos" />
              <SocialIcon src={youtube} alt="YouTube" href="https://www.youtube.com/channel/UC2_e1-9trV5x3beP_wXQfpw" />
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <Link to="/Home" style={styles.link}><button style={styles.button}>الرئيسيه</button></Link>
              <Link to="/Home" style={styles.link}><button style={styles.button}>عن المنصه</button></Link>
              <Link to="/Teachers" style={styles.link}><button style={styles.button}>مدرسينا</button></Link>


            </div>
            
            <div className="profile-name animiR">Acadmy</div>

            <img
  src={logo}
  alt="Logo"
  style={{
    position: 'absolute',
    width: '50%',  // Use percentage for responsive width
    maxWidth: '150px',  // Optional max size for larger screens
    height: 'auto',  // Maintain aspect ratio
    transition: 'transform 0.3s ease',
    left: '110%',  // Center horizontally with transform trick
    top: '20%',  // Adjust the vertical position as needed
    transform: 'translate(-50%, -50%)',  // Center the image both horizontally and vertically
  }}
  onMouseEnter={(e) => e.currentTarget.style.transform = 'translate(-50%, -50%) scale(1.1)'}
  onMouseLeave={(e) => e.currentTarget.style.transform = 'translate(-50%, -50%) scale(1)'}
/>

{/* Add media queries for different screen sizes */}
<style>
  {`
    @media (max-width: 768px) {
      img {
        width: 30%;  /* Larger size on smaller screens */
        top: 30%;    /* Adjust vertical position for smaller screens */
      }
    }

    @media (max-width: 480px) {
      img {
        width: 40%;  /* Further increase size for very small screens */
        top: 40%;    /* Adjust top for very small screens */
      }
    }
  `}
</style>
          </div>
        </header>

    </>
  );
}

export default Header;

const styles = {
  button: {
    height: "33px",
    background: "white",
    borderRadius: "7px",
    border: "1px solid #487EEA",
    color: "#487EEA",
    fontFamily: "Inter",
    fontWeight: "400",
    padding: "5 16px",
    cursor: "pointer",
    transition: "background 0.3s, color 0.3s",
  },
  link: {
    textDecoration: "none",
  },
};